import React from 'react'
import Seo from 'components/seo'
import { ResetCSS } from 'common/assets/css/style'

const NotFoundPage = () => (
    <>
        <Seo title='404: Not found' />
        <ResetCSS />
    </>
)

export default NotFoundPage
